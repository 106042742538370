import React, {
	Fragment,
	useEffect,
	useState,
	useMemo,
	useCallback,
} from "react";
import {
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Switch,
} from "@material-ui/core";
import { startCase, isEqual } from "lodash";
import CodeEditor from "@uiw/react-textarea-code-editor";

import { makeStyles } from "@material-ui/core/styles";

import { CTAOverride, useCTAContext } from "../_lib/context";

import { CTAProvider } from "../_lib/context";
import { CTA as CtaIframe } from "./_lib/cta";
import {
	allAssets,
	bgThemeImageList,
	bannerHeroBgThemeImages,
	bannerInlineHeroBgSrcsetImages,
} from "./_lib/asset-registry";
import { PrimaryButton } from "~/components/Button";
import { createCTAView, ViewComponent } from "./_lib/view";
import AddThemeForm from "./_lib/AddThemeForm";
import { useAuth } from "@api/auth";
import { useViewContext } from "~/state";
import {
	FeedbackProvider,
	useFeedbackContext,
} from "~/components/Feedback/context";
import { Redirect } from "~/components/Redirect";
import { Typography } from "~/components/Typography";
import { CTA } from "@api/ctas";
import { Loading } from "../../../_lib/Loading";
import { Input } from "~/components/Input";
import Select from "~/components/Select";
import { CtaType, ProductType } from "@api/graphql/types";
import CompanyNameTitle from "~/components/CompanyNameTitle";
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => {
	return {
		gridContainer: {
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				flexDirection: "column",
			},
		},
		inputContainer: {
			display: "flex",
			flexDirection: "column",
		},
		input: {
			margin: "10px 0",
		},
		developerModeSwitch: {
			margin: "20px 0px 10px 0px",
		},
		previewColumn: {
			width: "60%",
			padding: "15px",
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				width: "100%",
			},
		},
		codeColumn: {
			width: "40%",
			padding: "15px",
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				width: "100%",
			},
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			marginTop: "15px",
		},
		updateButton: {
			marginRight: "15px",
		},
		deleteButton: {
			background: "#C3002E",
			boxShadow: "none",
		},
		addThemeButton: {
			margin: "10px 0",
		},
	};
});

const defaultOverride = {
	templateDef: {
		message: [""],
	},
	theme: "default",
	themeDef: {},
};

const getCtaDefinitions = (selectedCta: any, companyCta?: CTAOverride) => {
	if (companyCta) {
		return JSON.stringify(
			{
				templateDef:
					companyCta.templateDef ||
					selectedCta?.templateDef.defs.default ||
					defaultOverride.templateDef,
				themeDef:
					companyCta.themeDef ||
					selectedCta?.themeDef.defs.default ||
					defaultOverride.themeDef,
			},
			null,
			2,
		);
	}

	return JSON.stringify(
		{
			templateDef: defaultOverride.templateDef,
			themeDef: defaultOverride.themeDef,
		},
		null,
		2,
	);
};

export const isValidJson = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

const CompanyView: ViewComponent = () => {
	const { user } = useAuth();
	const { handleOpenFeedback } = useFeedbackContext();
	const {
		actions: { openModal, closeModal },
	} = useViewContext();
	const { selectedCta, saveCTA, loading, state } = useCTAContext();
	const styles = useStyles();

	const widgetId = useMemo(() => {
		return state?.widgets?.nodes?.[0]?.id || "";
	}, [state]);

	const companyCta: CTA["companyCta"] = useMemo(() => {
		return (selectedCta?.companyCta || {}) as CTA["companyCta"];
	}, [selectedCta]);

	const themes: string[] = useMemo(() => {
		return (selectedCta?.themes || []) as string[];
	}, [selectedCta?.themes]);

	const [theme, setTheme] = useState(
		companyCta?.theme || defaultOverride.theme,
	);
	const [code, setCode] = useState(
		getCtaDefinitions(selectedCta, companyCta),
	);
	const [developerMode, setDeveloperMode] = useState(false);
	const [showAddThemeButton, setShowAddThemeButton] = useState(false);

	const [message, setMessage] = useState("");
	const [subMessage, setSubMessage] = useState(""); // DriveTool specific
	const [messageColor, setMessageColor] = useState("");
	const [paragraph, setParagraph] = useState("");
	const [paragraphColor, setParagraphColor] = useState("");
	const [launchButton, setLaunchButton] = useState("");
	const [equifaxLogo, setEquifaxLogo] = useState("");
	const [dealerLogo, setDealerLogo] = useState("");
	const [dealerLogoVisibility, setDealerLogoVisibility] = useState("");
	const [buttonCtaIcon, setButtonCtaIcon] = useState("");
	const [bgThemeImage, setBgThemeImage] = useState("");
	const [bgThemeColor, setBgThemeColor] = useState("");
	const [bgSrcsetValues, setBgSrcsetValues] = useState(() => ({
		imageSrc: "",
		imageSrc750plus: "",
		imageSrc1200plus: "",
		alt: "",
	}));
	// MSA specific
	const [heroImage, setHeroImage] = useState("");
	const [actionAsset, setActionAsset] = useState("");
	// DriveTool specific
	const [inputColor, setInputColor] = useState("");
	const [labelColor, setLabelColor] = useState("");
	const [buttonColor, setButtonColor] = useState("");
	const [buttonTextColor, setButtonTextColor] = useState("");
	const [buttonText, setButtonText] = useState("");
	const [datePickerLabel, setDatePickerLabel] = useState("");
	const [timePickerLabel, setTimePickerLabel] = useState("");
	// Current CTA Template & Theme Def states
	const [curTemplateDefs, setCtaTemplateDefs] = useState<Record<string, any>>(
		{},
	);
	const [curThemeDefs, setCtaThemeDefs] = useState<Record<string, any>>({});

	const hasMsgArray =
		selectedCta &&
		(selectedCta.product === ProductType.CreditTool ||
			selectedCta.product === ProductType.LenderTool);

	useEffect(() => {
		// If the retrieved theme is "kbb", reset the CTA to the default theme
		if (companyCta && companyCta.theme && companyCta.theme.includes("kbb")) {
			setTheme("default");
			const defaultTemplateDef = {
				...selectedCta?.templateDef.defs["default"],
				message:
					selectedCta?.templateDef.defs["default"].message ||
					selectedCta?.templateDef.defs.default
						?.message ||
					defaultOverride.templateDef.message,
			}
			const defaultThemeDef = selectedCta?.themeDef.defs["default"];
			setCode(
				JSON.stringify(
					{
						templateDef: defaultTemplateDef,
						themeDef: defaultThemeDef,
					},
					null,
					2,
				),
			);
			saveCTA({
				theme: "default",
				templateDef: defaultTemplateDef,
				themeDef: defaultThemeDef,
			});
		}

		setCode(getCtaDefinitions(selectedCta, companyCta));
		setTheme(companyCta?.theme || defaultOverride.theme);
		setCtaTemplateDefs(selectedCta?.templateDef.defs);
		setCtaThemeDefs(selectedCta?.themeDef.defs);
	}, [selectedCta, companyCta]);

	useEffect(() => {
		if (!isValidJson(code)) return;
		const codeObj = JSON.parse(code);
		if (codeObj && selectedCta) {
			const backgroundSrcset = codeObj.templateDef.backgroundSrcset;
			const msg = codeObj.templateDef.message;
			const subMsg = codeObj.templateDef.subMessage; // DriveTool specific
			const msgColor = codeObj.themeDef["message-color"];
			const p = codeObj.templateDef.paragraph;
			const pColor = codeObj.themeDef["paragraph-color"];
			const launchBtn = codeObj.templateDef.launchButton;
			const equifaxIcon = codeObj.templateDef.equifaxLogo;
			const dealerLogo = codeObj.templateDef.dealerLogo;
			const dealerLogoVisibility = codeObj.themeDef["logo-visibility"];
			const backgroundThemeImg = codeObj.themeDef["background-img"];
			const backgroundThemeColor = codeObj.themeDef["background-color"];
			// button-type CTA specific
			const buttonIcon = codeObj.templateDef.buttonIcon;
			// MSA specific
			const heroImage = codeObj.templateDef.heroImage;
			const actionAsset = codeObj.templateDef.actionAsset;
			// DriveTool specific
			const inputColor = codeObj.themeDef["input-color"];
			const labelColor = codeObj.themeDef["label-color"];
			const buttonColor = codeObj.themeDef["button-color"];
			const buttonTextColor = codeObj.themeDef["button-text-color"];
			const buttonText = codeObj.templateDef.buttonText;
			const datePickerLabel = codeObj.templateDef.datePickerLabel;
			const timePickerLabel = codeObj.templateDef.timePickerLabel;

			if (selectedCta.type === CtaType.Button) {
				msg && setMessage(msg);
				msgColor && setMessageColor(msgColor);
				backgroundThemeColor && setBgThemeColor(backgroundThemeColor);
				buttonIcon && setButtonCtaIcon(buttonIcon);
			} else if (selectedCta.product === ProductType.DriveTool) {
				msg && setMessage(msg);
				subMsg && setSubMessage(subMsg);
				msgColor && setMessageColor(msgColor);
				inputColor && setInputColor(inputColor);
				labelColor && setLabelColor(labelColor);
				buttonColor && setButtonColor(buttonColor);
				buttonTextColor && setButtonTextColor(buttonTextColor);
				buttonText && setButtonText(buttonText);
				datePickerLabel && setDatePickerLabel(datePickerLabel);
				timePickerLabel && setTimePickerLabel(timePickerLabel);
				backgroundThemeColor && setBgThemeColor(backgroundThemeColor);
				backgroundSrcset && setBgSrcsetValues({
					imageSrc: backgroundSrcset.src,
					imageSrc750plus: backgroundSrcset.bp["750px"],
					imageSrc1200plus: backgroundSrcset.bp["1200px"],
					alt: backgroundSrcset.alt,
				});
			} else if (
				selectedCta.product === ProductType.CreditTool ||
				selectedCta.product === ProductType.TradeTool ||				
				selectedCta.product === ProductType.LenderTool ||
				selectedCta.product === ProductType.MsaTool
			) {
				backgroundSrcset &&
					setBgSrcsetValues({
						imageSrc: backgroundSrcset.src,
						imageSrc750plus: backgroundSrcset.bp["750px"],
						imageSrc1200plus: backgroundSrcset.bp["1200px"],
						alt: backgroundSrcset.alt,
					});
				if (
					selectedCta.type === CtaType.Hero ||
					selectedCta.product === ProductType.TradeTool
				) {
					msg && setMessage(msg);
				} else {
					msg && setMessage(msg.join(" / "));
				}
				msgColor && setMessageColor(msgColor);
				p && setParagraph(p.join(" / "));
				pColor && setParagraphColor(pColor);
				selectedCta.type === CtaType.Hero
					? actionAsset && setActionAsset(actionAsset)
					: launchBtn && setLaunchButton(launchBtn);

				equifaxIcon && setEquifaxLogo(equifaxIcon);
				dealerLogo && setDealerLogo(dealerLogo);
				dealerLogoVisibility &&
					setDealerLogoVisibility(dealerLogoVisibility);
				backgroundThemeImg && setBgThemeImage(backgroundThemeImg);
				backgroundThemeColor && setBgThemeColor(backgroundThemeColor);
				if (selectedCta.type === CtaType.Hero) {
					heroImage && setHeroImage(heroImage);
				}
			}
		}
	}, [code, selectedCta]);

	// const [alignment, setAlignment] = useState("simple");
	// const handleChange = (
	//     event: React.MouseEvent<HTMLElement>,
	//     newAlignment: string,
	// ) => {
	//     setAlignment(newAlignment);
	// };

	const showAddThemeForm = useCallback(
		({ ctaId, code, themeList, curTemplateDefs, curThemeDefs, save }) => {
			openModal({
				content: (
					<AddThemeForm
						ctaId={ctaId}
						code={code}
						themeList={themeList}
						curTemplateDefs={curTemplateDefs}
						curThemeDefs={curThemeDefs}
						setShowAddThemeButton={setShowAddThemeButton}
						closeModal={closeModal}
						save={save}
					/>
				),
				props: {
					title: "Add New Theme",
				},
			});
		},
		[openModal, closeModal],
	);

	const content = useMemo(() => {
		const ctaProps = {
			product: selectedCta?.product,
			type: selectedCta?.type,
			company: widgetId,
		};
		const buttonText = !selectedCta?.companyCta
			? "Create Override"
			: "Save";

		const codeObj = isValidJson(code) && JSON.parse(code);
		const hasParagraph = codeObj && Boolean(codeObj.templateDef.paragraph);
		const hasBgSrcsetImg =
			codeObj && Boolean(codeObj.templateDef.backgroundSrcset);
		const hasBgThemeImg =
			codeObj && Boolean(codeObj.themeDef["background-img"]);
		const hasBgThemeColor =
			codeObj && Boolean(codeObj.themeDef["background-color"]);
		const hasEquifaxLogo =
			codeObj && Boolean(codeObj.templateDef.equifaxLogo);
		const hasDealerLogo =
			codeObj && Boolean(codeObj.templateDef.dealerLogo);
		const hasDealerLogoVisibility =
			codeObj && Boolean(codeObj.themeDef["logo-visibility"]);
		const hasLaunchButton =
			codeObj && Boolean(codeObj.templateDef.launchButton);
		// button-type CTA specific
		const hasButtonIcon =
			codeObj && Boolean(codeObj.templateDef.buttonIcon);
		// MSA specific
		const hasHeroImage = codeObj && Boolean(codeObj.templateDef.heroImage);
		const hasActionAsset =
			codeObj && Boolean(codeObj.templateDef.actionAsset);
		// DriveTool specific
		const hasSubMessage = 
			codeObj && Boolean(codeObj.templateDef.subMessage);
		const hasInputColor =
			codeObj && Boolean(codeObj.themeDef["input-color"]);
		const hasLabelColor =
			codeObj && Boolean(codeObj.themeDef["label-color"]);
		const hasButtonColor =
			codeObj && Boolean(codeObj.themeDef["button-color"]);
		const hasButtonTextColor =
			codeObj && Boolean(codeObj.themeDef["button-text-color"]);
		const hasDatePickerLabel =
			codeObj && Boolean(codeObj.templateDef.datePickerLabel);
		const hasTimePickerLabel =
			codeObj && Boolean(codeObj.templateDef.timePickerLabel);
		const hasButtonText =
			codeObj && Boolean(codeObj.templateDef.buttonText);

		const selectTheme = (
			e: React.ChangeEvent<{ name?: string; value: any }>,
		) => {
			const theme = e.target.value as string;
			setTheme(theme);
			setCode(
				JSON.stringify(
					{
						templateDef: {
							...selectedCta?.templateDef.defs[theme],
							message:
								selectedCta?.templateDef.defs[theme].message ||
								selectedCta?.templateDef.defs.default
									?.message ||
								defaultOverride.templateDef.message,
						},
						themeDef: selectedCta?.themeDef.defs[theme],
					},
					null,
					2,
				),
			);
		};

		const selectBackgroundImgSrc = (
			e: React.ChangeEvent<{ name?: string; value: any }>,
		) => {
			const bgImgSrc = e.target.value as string;
			setBgThemeImage(bgImgSrc);
		};

		const selectDealerLogo = (
			e: React.ChangeEvent<{ name?: string; value: any }>,
		) => {
			const dealerLogo = e.target.value as string;
			setDealerLogo(dealerLogo);
		};

		const selectDealerLogoVisibility = (
			e: React.ChangeEvent<{ name?: string; value: any }>,
		) => {
			const logoVisibility = e.target.value as string;
			setDealerLogoVisibility(logoVisibility);
		};

		const selectLaunchButton = (
			e: React.ChangeEvent<{ name?: string; value: any }>,
		) => {
			const launchButton = e.target.value as string;
			setLaunchButton(launchButton);
		};

		const selectBackgroundSrcset = (
			e: React.ChangeEvent<{ name?: string; value: any }>,
		) => {
			const bgSrc = e.target.value as string;
			const bgSrcset = bannerInlineHeroBgSrcsetImages.find(
				(srcset) => bgSrc === srcset.src,
			);
			if (!bgSrcset) return;

			setBgSrcsetValues({
				imageSrc: bgSrcset.src,
				imageSrc750plus: bgSrcset.bp["750px"],
				imageSrc1200plus: bgSrcset.bp["1200px"],
				alt: bgSrcset.alt,
			});
		};

		const changeCode = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
			setCode(e.target.value);
		};

		const setInputToCode = (codeObj: any) => {
			const messageVal = hasMsgArray ? message.split(" / ") : message;
			const paragraphArr = paragraph.split(" / ");
			codeObj.templateDef.message = messageVal;
			codeObj.themeDef["message-color"] = messageColor;
			if (hasBgSrcsetImg) {
				codeObj.templateDef.backgroundSrcset.src =
					bgSrcsetValues.imageSrc;
				codeObj.templateDef.backgroundSrcset.bp["750px"] =
					bgSrcsetValues.imageSrc750plus;
				codeObj.templateDef.backgroundSrcset.bp["1200px"] =
					bgSrcsetValues.imageSrc1200plus;
				codeObj.templateDef.backgroundSrcset.alt = bgSrcsetValues.alt;
			}
			if (hasParagraph) {
				codeObj.templateDef.paragraph = paragraphArr;
				codeObj.themeDef["paragraph-color"] = paragraphColor;
			}
			if (hasBgThemeImg) {
				codeObj.themeDef["background-img"] = bgThemeImage;
			}
			if (hasBgThemeColor) {
				codeObj.themeDef["background-color"] = bgThemeColor;
			}
			if (hasEquifaxLogo) {
				codeObj.templateDef.equifaxLogo = equifaxLogo;
			}
			if (hasDealerLogo) {
				codeObj.templateDef.dealerLogo = dealerLogo;
			}
			if (hasDealerLogoVisibility) {
				codeObj.themeDef["logo-visibility"] = dealerLogoVisibility;
			}
			if (hasLaunchButton) {
				codeObj.templateDef.launchButton = launchButton;
			}
			// button-type CTA specific
			if (hasButtonIcon) {
				codeObj.templateDef.buttonIcon = buttonCtaIcon;
			}
			// MSA specific
			if (hasHeroImage) {
				codeObj.templateDef.heroImage = heroImage;
			}
			if (hasActionAsset) {
				codeObj.templateDef.actionAsset = actionAsset;
			}
			// DriveTool specific
			if (hasSubMessage) {
				codeObj.templateDef.subMessage = subMessage;
			}
			if (hasInputColor) {
				codeObj.themeDef["input-color"] = inputColor;
			}
			if (hasLabelColor) {
				codeObj.themeDef["label-color"] = labelColor;
			}
			if (hasButtonColor) {
				codeObj.themeDef["button-color"] = buttonColor;
			}
			if (hasButtonTextColor) {
				codeObj.themeDef["button-text-color"] = buttonTextColor;
			}
			if (hasButtonText) {
				codeObj.templateDef.buttonText = buttonText;
			}
			if (hasDatePickerLabel) {
				codeObj.templateDef.datePickerLabel = datePickerLabel;
			}
			if (hasTimePickerLabel) {
				codeObj.templateDef.timePickerLabel = timePickerLabel;
			}
		};

		const save = (props: { delete?: boolean }) => {
			try {
				const cta = JSON.parse(code);

				if (!developerMode) {
					setInputToCode(cta);
				}
				saveCTA({
					theme: props.delete ? "default" : theme,
					templateDef: cta.templateDef,
					themeDef: cta.themeDef,
					...props,
				});

				const isDiff =
					!isEqual(curTemplateDefs[theme], cta.templateDef) ||
					!isEqual(curThemeDefs[theme], cta.themeDef);

				if (isDiff) {
					setShowAddThemeButton(true);
				}
				if (props.delete) {
					setShowAddThemeButton(false);
					handleOpenFeedback({
						message: "Custom CTA deleted.",
						severity: "warning",
					});
				} else if (!selectedCta?.companyCta) {
					handleOpenFeedback({
						message: "Custom CTA created.",
						severity: "success",
					});
				} else {
					handleOpenFeedback({
						message: "Custom CTA updated.",
						severity: "success",
					});
				}
			} catch (err) {
				console.log(err);
				handleOpenFeedback({
					message: "Must submit valid JSON.",
					severity: "error",
				});
			}
		};
		const updateCTA = () => {
			return save({});
		};
		const deleteCTA = () => {
			return save({ delete: true });
		};

		if (!user?.autocorpAdmin) {
			return <Redirect to="/leads/" />;
		}

		const bgSrcsetImgInputs = (
			<Fragment>
				<Typography variant="h4">Background Source Set</Typography>
				{selectedCta?.type === "BANNER_HERO" ||
				(selectedCta?.type as any) === "INLINE_HERO" ? (
					<FormControl fullWidth className={styles.input}>
						<Typography
							id="bg-srcset-label"
							variant="inputLabel"
							style={{ marginBottom: "5px" }}
						>
							Source Set Image
						</Typography>
						<Select
							labelId="bg-srcset-label"
							id="bg-srcset-dropdown"
							defaultValue={bgSrcsetValues.imageSrc || ""}
							value={[bgSrcsetValues.imageSrc || ""]}
							onChange={selectBackgroundSrcset}
							variant="outlined"
						>
							{bannerInlineHeroBgSrcsetImages.map(
								(bgSrcset, i) => {
									return (
										<MenuItem
											key={i}
											value={bgSrcset.src as string}
										>
											{startCase(
												bgSrcset.src.replace(
													/(.png)|(.jpg)/,
													"",
												),
											)}
										</MenuItem>
									);
								},
							)}
						</Select>
					</FormControl>
				) : (
					<>
						<FormControl fullWidth className={styles.input}>
							<Input
								label="Image Src"
								value={bgSrcsetValues.imageSrc}
								onChange={(e) => {
									setBgSrcsetValues({
										...bgSrcsetValues,
										imageSrc: e.target.value,
									});
								}}
								disabled
							/>
						</FormControl>
						<FormControl fullWidth className={styles.input}>
							<Input
								label="Image Src (min-width: 750px and up)"
								value={bgSrcsetValues.imageSrc750plus}
								onChange={(e) => {
									setBgSrcsetValues({
										...bgSrcsetValues,
										imageSrc750plus: e.target.value,
									});
								}}
								disabled
							/>
						</FormControl>
						<FormControl fullWidth className={styles.input}>
							<Input
								label="Image Src (min-width: 1200px and up)"
								value={bgSrcsetValues.imageSrc1200plus}
								onChange={(e) => {
									setBgSrcsetValues({
										...bgSrcsetValues,
										imageSrc1200plus: e.target.value,
									});
								}}
								disabled
							/>
						</FormControl>
						<FormControl fullWidth className={styles.input}>
							<Input
								label="Image Alt"
								value={bgSrcsetValues.alt}
								onChange={(e) => {
									setBgSrcsetValues({
										...bgSrcsetValues,
										alt: e.target.value,
									});
								}}
							/>
						</FormControl>
					</>
				)}
			</Fragment>
		);

		return (
			<Grid container className={styles.gridContainer}>
				<CompanyNameTitle companyName={state?.name} />
				<Grid item className={styles.previewColumn}>
					{loading ? (
						<Loading />
					) : (
						<Fragment>
							<CtaIframe
								{...ctaProps}
								label="Desktop View"
								height="500px"
							/>
							<CtaIframe
								{...ctaProps}
								label="Mobile View"
								maxWidth="320px"
								height="350px"
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item className={styles.codeColumn}>
					{!!theme && (
						<FormControl fullWidth className={styles.input}>
							<Typography id="cta-theme-label" variant="h4">
								Select a Theme
							</Typography>
							<Select
								labelId="cta-theme-label"
								id="cta-theme"
								defaultValue={theme || ""}
								value={[theme || ""]}
								onChange={selectTheme}
								variant="outlined"
							>
								{themes
									.slice()
									.sort((a, b) => {
										if (a === "default") {
											return -1;
										}
										if (b === "default") {
											return 1;
										}
										return a.localeCompare(b);
									})
									.map((theme: string) => {
										return (
											<MenuItem key={theme} value={theme}>
												{startCase(theme)}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					)}

					{showAddThemeButton && (
						<PrimaryButton
							onClick={() =>
								showAddThemeForm({
									ctaId: selectedCta?.id,
									code,
									themeList: themes,
									curTemplateDefs,
									curThemeDefs,
									save: updateCTA,
								})
							}
							className={styles.addThemeButton}
						>
							Add as New Theme
						</PrimaryButton>
					)}

					{loading && <Loading />}

					{!developerMode && (
						<div
							className={styles.inputContainer}
							style={{
								visibility: loading ? "hidden" : "visible",
							}}
						>
							{/* <Typography variant="h4">Variant Name</Typography>
                            <FormControl fullWidth>
                                <Input placeholder="Name of Variant"/>
                            </FormControl> */}

							<Typography variant="h4">Customization</Typography>
							<Typography
								variant="subtitle1"
								style={{ fontSize: "15px" }}
							>
								Enter the CTA message below. (Separate messages
								with a slash (`/`) to place them on a new line
								when applicable)
							</Typography>
							<FormControl fullWidth className={styles.input}>
								<Input
									label="Message"
									value={message}
									onChange={(e) => setMessage(e.target.value)}
								/>
							</FormControl>
							{/* DriveTool specific */}
							{subMessage && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Sub-Message"
										value={subMessage}
										onChange={(e) => setSubMessage(e.target.value)}
									/>
								</FormControl>
							)}
							<FormControl fullWidth className={styles.input}>
								<Input
									label="Message Color"
									value={messageColor}
									onChange={(e) =>
										setMessageColor(e.target.value)
									}
								/>
							</FormControl>
							{hasParagraph && (
								<Fragment>
									<FormControl
										fullWidth
										className={styles.input}
									>
										<Input
											label="Paragraph"
											value={paragraph}
											onChange={(e) =>
												setParagraph(e.target.value)
											}
										/>
									</FormControl>
									<FormControl
										fullWidth
										className={styles.input}
									>
										<Input
											label="Paragraph Color"
											value={paragraphColor}
											onChange={(e) =>
												setParagraphColor(
													e.target.value,
												)
											}
										/>
									</FormControl>
								</Fragment>
							)}
							{hasLaunchButton && (
								<FormControl fullWidth className={styles.input}>
									<Typography
										id="launch-button-label"
										variant="inputLabel"
										style={{ marginBottom: "5px" }}
									>
										Launch Button
									</Typography>
									<Select
										labelId="launch-button-label"
										id="launch-button-dropdown"
										defaultValue={launchButton || ""}
										value={[launchButton || ""]}
										onChange={selectLaunchButton}
										variant="outlined"
									>
										{allAssets.launchButtons
											.slice()
											.sort()
											.map((launchButton, i) => {
												return (
													<MenuItem
														key={i}
														value={launchButton}
													>
														{startCase(
															launchButton.replace(
																/(.png)|(.jpg)|(.svg)/,
																"",
															),
														)}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							)}
							{hasEquifaxLogo && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Equifax Logo"
										value={equifaxLogo}
										onChange={(e) =>
											setEquifaxLogo(e.target.value)
										}
										disabled
									/>
								</FormControl>
							)}
							{hasDealerLogo && (
								<FormControl fullWidth className={styles.input}>
									<Typography
										id="dealer-logo-label"
										variant="inputLabel"
										style={{ marginBottom: "5px" }}
									>
										Dealer Logo
									</Typography>
									<Select
										labelId="dealer-logo-label"
										id="dealer-logo-dropdown"
										defaultValue={dealerLogo || ""}
										value={[dealerLogo || ""]}
										onChange={selectDealerLogo}
										variant="outlined"
									>
										{allAssets.dealerLogos.map(
											(dLogo, i) => {
												return (
													<MenuItem
														key={i}
														value={dLogo}
													>
														{startCase(
															dLogo.replace(
																/(.png)|(.jpg)|(.svg)/,
																"",
															),
														)}
													</MenuItem>
												);
											},
										)}
									</Select>
								</FormControl>
							)}
							{hasDealerLogoVisibility && (
								<FormControl fullWidth className={styles.input}>
									<Typography
										id="dealer-logo-visibility-label"
										variant="inputLabel"
										style={{ marginBottom: "5px" }}
									>
										Dealer Logo Visibility
									</Typography>
									<Select
										labelId="dealer-logo-visibility-label"
										id="dealer-logo-visibility-dropdown"
										defaultValue={
											dealerLogoVisibility || "visible"
										}
										value={[
											dealerLogoVisibility || "visible",
										]}
										onChange={selectDealerLogoVisibility}
										variant="outlined"
									>
										<MenuItem key={0} value="visible">
											{startCase("visible")}
										</MenuItem>
										<MenuItem key={1} value="hidden">
											{startCase("hidden")}
										</MenuItem>
									</Select>
								</FormControl>
							)}
							{/* MSA specific */}
							{hasHeroImage && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Hero Image"
										value={heroImage}
										onChange={(e) =>
											setHeroImage(e.target.value)
										}
										disabled
									/>
								</FormControl>
							)}
							{hasActionAsset && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Action Asset"
										value={actionAsset}
										onChange={(e) =>
											setActionAsset(e.target.value)
										}
										disabled
									/>
								</FormControl>
							)}
							{/* Button-type CTA specific */}
							{hasButtonIcon && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Button Icon"
										value={buttonCtaIcon}
										onChange={(e) =>
											setButtonCtaIcon(e.target.value)
										}
										disabled
									/>
								</FormControl>
							)}

							{hasBgSrcsetImg && bgSrcsetImgInputs}

							{(hasBgThemeImg || hasBgThemeColor) && (
								<Typography variant="h4">
									Background Theme
								</Typography>
							)}
							{hasBgThemeImg && theme && selectedCta && (
								<FormControl fullWidth className={styles.input}>
									<Typography
										id="background-img-src-label"
										variant="inputLabel"
										style={{ marginBottom: "5px" }}
									>
										Image Source
									</Typography>
									<Select
										labelId="background-img-src-label"
										id="cta-background-img-dropdown"
										defaultValue={bgThemeImage || ""}
										value={[bgThemeImage || ""]}
										onChange={selectBackgroundImgSrc}
										variant="outlined"
									>
										{selectedCta.type !== "BANNER_HERO"
											? bgThemeImageList
													.slice()
													.sort((a, b) => {
														if (a === "") {
															return -1;
														}
														if (b === "") {
															return 1;
														}
														return a.localeCompare(
															b,
														);
													})
													.map((bgThemeImg, i) => {
														return (
															<MenuItem
																key={i}
																value={
																	bgThemeImg
																}
															>
																{startCase(
																	bgThemeImg.replace(
																		/(.png)|(.jpg)|(.svg)/,
																		"",
																	),
																)}
															</MenuItem>
														);
													})
											: bannerHeroBgThemeImages
													.slice()
													.sort((a, b) => {
														if (a === "") {
															return -1;
														}
														if (b === "") {
															return 1;
														}
														return a.localeCompare(
															b,
														);
													})
													.map((bgImg, i) => {
														return (
															<MenuItem
																key={i}
																value={bgImg}
															>
																{startCase(
																	bgImg.replace(
																		/(.png)|(.jpg)/,
																		"",
																	),
																)}
															</MenuItem>
														);
													})}
									</Select>
								</FormControl>
							)}
							{hasBgThemeColor && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Theme Color"
										value={bgThemeColor}
										onChange={(e) =>
											setBgThemeColor(e.target.value)
										}
									/>
								</FormControl>
							)}
							{/* DriveTool specific */}
							{hasInputColor && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Input Colors"
										value={inputColor}
										onChange={(e) =>
											setInputColor(e.target.value)
										}
									/>
								</FormControl>
							)}
							{hasLabelColor && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Label Colors"
										value={labelColor}
										onChange={(e) =>
											setLabelColor(e.target.value)
										}
									/>
								</FormControl>
							)}
							{hasButtonColor && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Button Color"
										value={buttonColor}
										onChange={(e) =>
											setButtonColor(e.target.value)
										}
									/>
								</FormControl>
							)}
							{hasButtonTextColor && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Button Text Color"
										value={buttonTextColor}
										onChange={(e) =>
											setButtonTextColor(e.target.value)
										}
									/>
								</FormControl>
							)}
							{hasButtonText && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Button Text"
										value={buttonText}
										onChange={(e) => setButtonText(e.target.value)}
									/>
								</FormControl>
							)}
							{hasDatePickerLabel && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Date Picker Label"
										value={datePickerLabel}
										onChange={(e) => setDatePickerLabel(e.target.value)}
									/>
								</FormControl>
							)}
							{hasTimePickerLabel && (
								<FormControl fullWidth className={styles.input}>
									<Input
										label="Time Picker Label"
										value={timePickerLabel}
										onChange={(e) => setTimePickerLabel(e.target.value)}
									/>
								</FormControl>
							)}
						</div>
					)}

					{developerMode && (
						<Fragment>
							<Typography variant="h4" paragraph>
								Template
							</Typography>
							<CodeEditor
								value={code}
								language="json"
								placeholder="Please enter JSON code."
								onChange={changeCode}
								padding={15}
								style={{
									fontSize: 12,
									backgroundColor: "#f5f5f5",
									fontFamily:
										"ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
								}}
							/>
						</Fragment>
					)}

					<FormControlLabel
						className={styles.developerModeSwitch}
						label="Developer Mode"
						control={
							<Switch
								checked={developerMode}
								onChange={(e) =>
									setDeveloperMode(e.target.checked)
								}
								name="developerModeSwitch"
							/>
						}
					/>

					<div className={styles.buttonContainer}>
						<PrimaryButton
							onClick={updateCTA}
							className={styles.updateButton}
						>
							{buttonText}
						</PrimaryButton>
						<PrimaryButton
							onClick={deleteCTA}
							className={styles.deleteButton}
						>
							Delete Override
						</PrimaryButton>
					</div>
				</Grid>
			</Grid>
		);
	}, [
		loading,
		user,
		styles,
		code,
		setCode,
		saveCTA,
		handleOpenFeedback,
		setShowAddThemeButton,
		theme,
		themes,
		selectedCta,
		curTemplateDefs,
		curThemeDefs,
		widgetId,
		developerMode,
		showAddThemeButton,
		bgSrcsetValues,
		bgThemeColor,
		bgThemeImage,
		equifaxLogo,
		dealerLogo,
		dealerLogoVisibility,
		launchButton,
		message,
		subMessage,
		messageColor,
		paragraph,
		paragraphColor,
		buttonCtaIcon,
		hasMsgArray,
		heroImage,
		actionAsset,
		inputColor,
		labelColor,
		buttonColor,
		buttonTextColor,
		buttonText,
		datePickerLabel,
		timePickerLabel,
	]);

	return <Fragment>{content}</Fragment>;
};

const CTAView: ViewComponent = (props) => {
	return (
		<FeedbackProvider>
			<CTAProvider {...props}>
				<CompanyView {...props} />
			</CTAProvider>
		</FeedbackProvider>
	);
};

export default createCTAView(CTAView, {});
